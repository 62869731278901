<template>
  <section>
    <title-section
      title="Línea de productos"
    >
      <div class="level-item">
        <router-link class="button button_micro_dark" to="/productos/agregar-lineas">
          AGREGAR NUEVA LÍNEA
        </router-link>
      </div>
    </title-section>
    <main-lines />
  </section>
</template>

<script>
export default {
  name: 'Lines',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    MainLines: () => import('@/components/Products/Lines/MainLines.vue')
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 15px;
    width: 200px;
  }
</style>
